const BLACKFRIDAY_DATES = {
  start: new Date(2024, 10, 25, 0, 0),
  end: new Date(2024, 11, 2, 23, 59)
}

const isBlackFriday = (date: Date = new Date()): boolean => {
  return date >= BLACKFRIDAY_DATES.start && date <= BLACKFRIDAY_DATES.end
}

export { BLACKFRIDAY_DATES }
export default isBlackFriday
