import React, { useState, useRef, useEffect, RefObject } from "react"
import parse from "html-react-parser"
import "./Faq.css"
import crossIconUrl from "../../../../assets/img/cross.svg"
import { useNavigate } from "react-router-dom"

type FaqData = {
  question: string
  answer: string
}

const faqsData: FaqData[] = [
  {
    question: "¿Cuándo estará activa la promoción de Black Friday?",
    answer:
      "<p>La promoción de Black Friday en Vikika Team será válida desde el <strong>lunes 25 de noviembre</strong> hasta el <strong>domingo 1 de diciembre</strong>.</p>"
  },
  {
    question: "¿Puedo comprar ahora y empezar mi plan después?",
    answer:
      "<p>Las compras de planes realizadas durante Black Friday deberán comenzar a usarse en un máximo de <strong>15 días después de la compra</strong>.</p><p>No es posible iniciar el plan en enero o en fechas más tardías.</p>"
  },
  {
    question: "¿Los usuarios actuales tienen algún descuento en Black Friday?",
    answer:
      '<p><strong>Si</strong>, los usuarios que ya tienen una suscripción activa, pueden hacer uso de un <strong>10% de descuento</strong> en su siguiente renovación del plan.</p><p>Debes haber recibido <strong>un email</strong> por nuestra parte con las instrucciones para poder aplicarlo.</p><p>Revisa tu bandeja spam o no deseado o <strong>escríbenos si no lo localizas</strong> a nuestro email <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a></p>'
  },
  {
    question: "¿Qué descuentos estarán disponibles durante Black Friday para usuarios nuevos?",
    answer:
      "<p>Durante el Black Friday, ofreceremos los siguientes descuentos exclusivos <strong>para usuarios nuevos:</strong></p><ul><li><strong>Planes de Dieta:</strong> 20% de descuento</li><li><strong>Planes de Pareja:</strong> 15% de descuento</li><li><strong>Vital Wellness:</strong> 15% de descuento</li><li><strong>Psicología:</strong> 15% de descuento, tanto en suscripciones individuales como en pareja, <strong>¡y también aplica a bonos!</strong></li></ul><p>Aprovecha estas ofertas para comenzar o continuar tu camino hacia el bienestar.</p>"
  },
  {
    question: "¿Cómo puedo acceder a las oferta si soy un nuevo usuario?",
    answer:
      '<p>Las compras con descuento se realizarán a través de la landing en <a href="https://vikika.es/blackfriday">vikika.es/blackfriday</a>.</p><p>Al seleccionar el producto que quieras comprar, se te pedirá que completes tus datos para crear tu perfil de usuario y podrás finalizar la compra directamente con el descuento aplicado.</p>'
  },
  {
    question:
      "¿Cómo puedo acceder a las ofertas si soy usuario anterior, pero sin suscripción activa?",
    answer:
      '<p>Accede a tu Panel Personal en <a href="http://vikika.es/iniciar-sesion" target="_blank">vikika.es/iniciar-sesion</a>.</p><p>Si ya has tenido suscripciones con nosotros y están canceladas, tu usuario es tu email.</p><p>Si no recuerdas la contraseña, selecciona la opción ¿Olvidaste la contraseña? para generar una nueva.</p><p>Luego, entra en la landing en <a href="https://vikika.es/blackfriday">vikika.es/blackfriday</a> para comprar tu plan de nutrición o psicología.</p>'
  },
  {
    question: "¿Qué sucede si tengo problemas al realizar el pago?",
    answer:
      '<p>Si tienes problemas para finalizar el pago, <strong>revisa que la información en tu perfil esté correcta</strong> y reconoce los datos, luego debes acceder a la landing en <a href="https://vikika.es/blackfriday">vikika.es/blackfriday</a>.</p><p>Si el problema persiste, contáctanos a través de nuestro chat de la web en <a href="http://vikika.es" target="_blank">vikika.es</a> o al email <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a>.</p>'
  },
  {
    question: "¿Cuando podré agendar mis sesiones de psicología tras el pago?",
    answer:
      '<p>Una vez completes tu pago, recibirás un correo electrónico con los próximos pasos a seguir.</p><p>En este correo, te pediremos que firmes y envíes un consentimiento informado para responder a <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a>, junto con una breve descripción de tu motivo de consulta.</p><p>Una vez que envíes el consentimiento firmado y tu motivo de consulta, tu documento será archivado y asignado a un psicólogo, en ese momento podrás agendar tus sesiones acordes al plan contratado.</p><p>Accede a tu perfil de usuario en <a href="http://vikika.es/iniciar-sesion" target="_blank">vikika.es/iniciar-sesion</a>.</p><p>Ten en cuenta que, si has activado una suscripción, los 30 días para la renovación empezarán a contar desde ese momento, no olvides agendar todas tus sesiones.</p>'
  },
  {
    question: "¿Puedo usar este descuento junto a otro?",
    answer:
      "<p>Los descuentos no son acumulables.</p><p><strong>No es posible</strong> combinar el descuento de Black Friday con otras promociones.</p>"
  },
  {
    question:
      "¿Dónde puedo ver más información acerca del plan de dieta/entrenamiento y saber si es para mi?",
    answer:
      '<p>Puedes acceder aquí: <a href="https://vikika.es/productos/dieta" target="_blank">https://vikika.es/productos/dieta</a> y consultar todos los detalles del plan.</p><p>Si aun así sigues teniendo dudas, contáctanos a través de nuestro chat de la web en <a href="http://vikika.es" target="_blank">vikika.es</a> o al email <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a>.</p>'
  },
  {
    question:
      "¿Dónde puedo ver más información acerca del plan de psicología y saber si es para mí?",
    answer:
      '<p>Puedes acceder aquí: <a href="https://vikika.es/productos/psicologia" target="_blank">https://vikika.es/productos/psicologia</a> y consultar todos los detalles del plan.</p><p>Si aun así sigues teniendo dudas, contáctanos a través de nuestro chat de la web en <a href="http://vikika.es" target="_blank">vikika.es</a> o al email <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a>.</p>'
  },
  {
    question:
      "¿Dónde puedo ver más información acerca del plan vital wellness y saber si es para mí?",
    answer:
      '<p>Puedes acceder aquí: <a href="https://vitalwellnessbyvikikos.com" target="_blank">https://vitalwellnessbyvikikos.com</a> y consultar todos los detalles del plan.</p><p>Si aun así sigues teniendo dudas, contáctanos a través de nuestro chat de la web en <a href="http://vikika.es" target="_blank">vikika.es</a> o al email <a href="mailto:attcliente@vikika.es">attcliente@vikika.es</a>.</p>'
  },
  {
    question: "¿Dónde puedo ver cuáles son las condiciones de blackfriday?",
    answer:
      '<p>A través de este link: <a href="https://vikika.es/condiciones-generales">https://vikika.es/condiciones-generales</a> podrás ver todas las condiciones de los productos, así como las específicas de blackfriday.</p>'
  }
]

export default function Faq() {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const contentRefs = useRef<Array<RefObject<HTMLDivElement>>>(
    faqsData.map(() => React.createRef())
  )

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  useEffect(() => {
    contentRefs.current.forEach((ref, idx) => {
      if (ref.current) {
        ref.current.style.maxHeight = idx === activeIndex ? `${ref.current.scrollHeight}px` : "0px"
      }
    })
  }, [activeIndex])

  const moreFaqs = () => {
    navigate(`/faq`)
  }

  return (
    <div className="bf-faq">
      <div className="bf-faq-container">
        <div className="bf-faq-title-wrapper">
          <h2 className="bf-faq-title">Preguntas frecuentes</h2>
          <p className="bf-faq-title-subtitle">
            Todas las preguntas y respuestas sobre Vikika Team
          </p>
        </div>

        <div className="bf-faq-wrapper">
          {faqsData.map((faq, index) => (
            <div key={index} className="bf-faq-item">
              <div
                className="bf-faq-question"
                onClick={() => toggleFAQ(index)}
                aria-expanded={activeIndex === index}
              >
                {faq.question}
                <img
                  src={crossIconUrl}
                  alt="Toggle"
                  className={`arrow-icon ${activeIndex === index ? "rotate" : ""}`}
                />
              </div>
              <div
                ref={contentRefs.current[index]}
                className={`bf-faq-answer ${activeIndex === index ? "open" : ""}`}
              >
                <div className="bf-faq-answer-wrapper">{parse(faq.answer)}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="bf-faq-button-wrapper">
          <button onClick={() => moreFaqs()} className="bf-faq-button">
            ¿QUIERES SABER MÁS?
          </button>
        </div>
      </div>
    </div>
  )
}
