import './PromoButton.css'

interface PromoButtonProps {
  text: string
  disabled?: boolean
  onClick?: any
}

export default function PromoButton({ text, disabled=false, onClick }: PromoButtonProps) {
  return (
    <button className='bf-promo-cta' disabled={disabled} onClick={onClick}>
      {text}
    </button>
  )
}
