import React, { useEffect, useState } from "react"
import Divider from "@mui/material/Divider"
import "./ConfirmPayment.css"
import { FacturationData } from "../../../../models/FacturationData"
import { fetchClientSecretService } from "../../../../services/fetchClientSecretService"
import { useLocation, useNavigate } from "react-router-dom"
import Loading from "../../../shared/loading/Loading"
import { loginService } from "../../../../services/loginService"
import { Typography } from "@mui/material"
import { PartnerData } from "../../../../models/PartnerData"
import ToastMsgComponent from "../../../shared/toast/ToastMsg"
import { isAxiosError } from "axios"
import { NO_SERVER_RESPONSE_ERROR } from "../../../../utils/constants"
import { couponValidate } from "../../../../services/couponValidate"
import Close from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"

interface ConfirmPaymentProps {
  facturationData: FacturationData
  partnerData: PartnerData
  setShouldFetch: (prevState: boolean) => void
  selectedProduct: any
  formValid: boolean
  partnerFormValid: boolean
  confirmPaymentAction: boolean
  setConfirmPaymentAction: (value: boolean) => void
  isEnPareja: boolean
  cuponData: any
  setCuponData: (value: any) => void
}

const ConfirmPayment = (confirmPaymentProps: ConfirmPaymentProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [cuponValue, setCuponValue] = useState("")
  const [showModalLogin, setShowModalLogin] = useState(false)
  const [disableButtonOnConfirm, setDisableButtonOnConfirm] = useState(false)
  const [showFailureCouponValidateToast, setShowFailureCouponValidateToast] = useState(false)
  const [disableButtonOnCoupon, setDisableButtonOnCoupon] = useState(false)
  const getProductPrice = () => {
    if (confirmPaymentProps.selectedProduct.productSubscription) {
      return confirmPaymentProps.cuponData?.discount
        ? confirmPaymentProps.selectedProduct.productSubscriptionPrice +
            confirmPaymentProps.selectedProduct.productPrice -
            confirmPaymentProps.cuponData.discount
        : confirmPaymentProps.selectedProduct.productSubscriptionPrice +
            confirmPaymentProps.selectedProduct.productPrice
    }
    return confirmPaymentProps.cuponData?.discount
      ? confirmPaymentProps.selectedProduct.productPrice - confirmPaymentProps.cuponData.discount
      : confirmPaymentProps.selectedProduct.productPrice
  }
  const [showFailureToast, setShowFailureToast] = useState(false)
  const [showFailureSameProductToast, setShowFailureSameProductToast] = useState(false)

  useEffect(() => {
    if (confirmPaymentProps.confirmPaymentAction) {
      confirmPaymentProps.setConfirmPaymentAction(false)
      handleClick(null)
    }
  }, [confirmPaymentProps.confirmPaymentAction])

  useEffect(() => {
    if (Object.keys(confirmPaymentProps.selectedProduct).length > 0 && !cuponValue) {
      const searchParams = new URLSearchParams(location.search)
      const coupon = searchParams.get("coupon")

      if (coupon) {
        setCuponValue(coupon)
        handleValidateCoupon(coupon)
      }
    }
  }, [confirmPaymentProps])

  useEffect(() => {
    setDisableButtonOnConfirm(false)
  }, [])

  const handleClick = (event: any) => {
    setDisableButtonOnConfirm(true)
    async function fetchClientSecret() {
      try {
        setIsLoading(true)
        const price = getProductPrice()
        console.log("getProductPrice(): ", price)
        console.log(
          "confirmPaymentProps.cuponData?.discount: ",
          confirmPaymentProps.cuponData?.discount
        )
        const response = await fetchClientSecretService({
          amount:
            (getProductPrice() + parseFloat(confirmPaymentProps.cuponData?.discount || 0)) * 100,
          email: confirmPaymentProps.facturationData.email,
          password: confirmPaymentProps.facturationData.password,
          first_name: confirmPaymentProps.facturationData.firstName,
          last_name: confirmPaymentProps.facturationData.lastName,
          address_1: confirmPaymentProps.facturationData.address_1,
          address_2: confirmPaymentProps.facturationData.address_2,
          city: confirmPaymentProps.facturationData.city,
          postcode: confirmPaymentProps.facturationData.postalCode,
          country: confirmPaymentProps.facturationData.country,
          phone: confirmPaymentProps.facturationData.phone,
          state: confirmPaymentProps.facturationData.state,
          partner_name: confirmPaymentProps.partnerData.partner_name,
          partner_last_name: confirmPaymentProps.partnerData.partner_last_name,
          partner_email: confirmPaymentProps.partnerData.partner_email,
          product_id: confirmPaymentProps.selectedProduct.productId,
          variant_id: confirmPaymentProps.selectedProduct.variantId,
          product_subscription: confirmPaymentProps.selectedProduct.productSubscription,
          quantity: 1,
          cupon_code: confirmPaymentProps.cuponData?.cupon_code || "",
          price: parseInt((price * 100).toString())
        })
        console.log("processOrderResponse", response)
        setIsLoading(false)
        const datoExistente = localStorage.getItem("payment_intent")
        if (!datoExistente) {
          localStorage.setItem("payment_intent", response.data.payment_intent)
          localStorage.setItem("client_secret", response.data.client_secret)
          localStorage.setItem("wp_customer_id", response.data.wp_customer.id)
          localStorage.setItem("stripe_customer_id", response.data.stripe_customer.id)
          localStorage.setItem("wp_customer_status", response.data.wp_customer.status)
        }
        confirmPaymentProps.setShouldFetch(true)

        if (
          response.data.wp_customer.status === "already exists" &&
          String(response.data.wp_customer.id) !== localStorage.getItem("id")
        ) {
          try {
            setIsLoading(true)
            const responseLogin = await loginService({
              username: confirmPaymentProps.facturationData.email,
              password: confirmPaymentProps.facturationData.password
            })
            setIsLoading(false)
            if (responseLogin && responseLogin.data) {
              localStorage.setItem("status", "isLoggedIn")
              localStorage.setItem("id", responseLogin.data.id)
              localStorage.setItem("email", responseLogin.data.email)
              localStorage.setItem("nicename", responseLogin.data.nicename)
              localStorage.setItem("firstName", responseLogin.data.billing_info.first_name)
              localStorage.setItem("lastName", responseLogin.data.billing_info.last_name)
              localStorage.setItem("displayName", responseLogin.data.displayName)
              localStorage.setItem("token", responseLogin.data.token)
              localStorage.setItem("address_1", responseLogin.data.billing_info.address_1)
              localStorage.setItem("address_2", responseLogin.data.billing_info.address_2)
              localStorage.setItem("city", responseLogin.data.billing_info.city)
              localStorage.setItem("state", responseLogin.data.billing_info.state)
              localStorage.setItem("postcode", responseLogin.data.billing_info.postcode)
              localStorage.setItem("country", responseLogin.data.billing_info.country)
              localStorage.setItem("tokenExpiration", responseLogin.data.tokenExpiration)
              localStorage.setItem("phone", responseLogin.data.billing_info.phone)
              localStorage.setItem(
                "pon_user_info",
                JSON.stringify(responseLogin.data.pon_user_info)
              )
              const section = document.getElementById("payment-card-form")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              } else {
                setTimeout(() => {
                  const section = document.getElementById("payment-card-form")
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" })
                  }
                }, 500)
              }
            } else {
              localStorage.setItem(
                "facturationData",
                JSON.stringify(confirmPaymentProps.facturationData)
              )
              localStorage.setItem("nextViewAfterLogin", location.pathname)
              setShowModalLogin(true)
            }
          } catch (e) {
            console.log(e)
            localStorage.setItem(
              "facturationData",
              JSON.stringify(confirmPaymentProps.facturationData)
            )
            localStorage.setItem("nextViewAfterLogin", location.pathname)
            setIsLoading(false)
            setShowModalLogin(true)
          }
        } else {
          const section = document.getElementById("payment-card-form")
          if (section) {
            section.scrollIntoView({ behavior: "smooth" })
          } else {
            setTimeout(() => {
              const section = document.getElementById("payment-card-form")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            }, 500)
          }
        }
      } catch (error) {
        if (isAxiosError(error)) {
          console.log("error: ", error.response)
          if (error.response && error.response.status && error.response.status === 403) {
            setShowFailureSameProductToast(true)
            setTimeout(() => {
              setShowFailureSameProductToast(false)
            }, 10000)
          } else {
            setShowFailureToast(true)
            setTimeout(() => {
              setShowFailureToast(false)
            }, 10000)
          }
        } else {
          setShowFailureToast(true)
          setTimeout(() => {
            setShowFailureToast(false)
          }, 10000)
        }
        setDisableButtonOnConfirm(false)
        setIsLoading(false)
      }
    }
    fetchClientSecret()
  }

  const validateCupon = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCuponValue(event.target.value)
  }

  const handleValidateCoupon = async (couponCode: string = cuponValue) => {
    setIsLoading(true)
    setDisableButtonOnCoupon(true)
    try {
      const couponValidateResponse = await couponValidate(
        {
          product_id: confirmPaymentProps.selectedProduct.productId,
          variation_id: confirmPaymentProps.selectedProduct.variantId
        },
        couponCode
      )
      if (!couponValidateResponse) {
        setIsLoading(false)
        setDisableButtonOnCoupon(false)
        setShowFailureCouponValidateToast(true)
        setTimeout(() => {
          setShowFailureCouponValidateToast(false)
        }, 10000)
        return
      } else {
        confirmPaymentProps.setCuponData({
          type: couponValidateResponse.type,
          amount: couponValidateResponse.amount,
          discount: couponValidateResponse.discount,
          price_with_discount: couponValidateResponse.price_with_discount,
          cupon_code: couponCode
        })
        setIsLoading(false)
        setDisableButtonOnCoupon(false)
        return
      }
    } catch (error) {
      setIsLoading(false)
      setDisableButtonOnCoupon(false)
      setShowFailureCouponValidateToast(true)
      setTimeout(() => {
        setShowFailureCouponValidateToast(false)
      }, 10000)
    }
  }

  return (
    <div className="confirm-payment-card">
      {showModalLogin && (
        <div className={"modal-login-container"}>
          <div className={"modal-login"}>
            <Typography className={"modal-login-text"}>
              Ya tienes una cuenta en{" "}
              <b style={{ fontFamily: "Montserrat SemiBold" }}>VIKIKA TEAM.</b> Para poder comprar
              un nuevo producto, deberás iniciar sesión.
            </Typography>
            <button
              className={"modal-login-button"}
              disabled={!confirmPaymentProps.formValid}
              onClick={() => navigate("/iniciar-sesion")}
            >
              Iniciar sesión
            </button>
          </div>
        </div>
      )}
      {showFailureCouponValidateToast && (
        <ToastMsgComponent
          type="danger"
          titleHeader="Cupón Inválido"
          info="Has entrado un cupón que no es válido. Si crees que es un error ponte en contacto con nosotros."
          position="top-end"
        />
      )}
      {showFailureToast && (
        <ToastMsgComponent
          type="danger"
          titleHeader="Error al procesar la orden"
          info="Ha habido un error al procesar tu orden, ponte en contacto con nosotros"
          position="top-end"
        />
      )}
      {showFailureSameProductToast && (
        <ToastMsgComponent
          type="danger"
          titleHeader="Error al procesar la orden"
          info="Tienes una suscripción activa. No puedes comprar el mismo producto mientras esté activa la suscripción."
          position="top-end"
        />
      )}
      {isLoading && <Loading />}
      {confirmPaymentProps.selectedProduct && confirmPaymentProps.selectedProduct.name && (
        <>
          <div className="confirm-payment-card-header">
            <div className="confirm-payment-card-title">Total del pedido</div>
          </div>
          <div className="confirm-payment-body-up">
            <div className="confirm-payment-promotions">
              <p> Código promocional</p>
              <div className="promotional-section">
                <input
                  type="text"
                  placeholder="Código de cupón"
                  id="cupon-value-input"
                  value={cuponValue}
                  onChange={event => validateCupon(event)}
                />
                <IconButton
                  onClick={() => {
                    confirmPaymentProps.setCuponData(null)
                    setCuponValue("")
                    const elemento = document.querySelector(
                      "input#cupon-value-input"
                    ) as HTMLInputElement | null
                    if (elemento) {
                      elemento.value = ""
                      console.log("elemento: ", elemento)
                    } else {
                      console.log("elemento no encontrado")
                    }
                  }}
                  style={{
                    width: "25px",
                    height: "20px",
                    top: "5px",
                    marginLeft: "-30px",
                    color: "red"
                  }}
                >
                  <Close />
                </IconButton>
                <button
                  disabled={disableButtonOnCoupon || cuponValue === ""}
                  onClick={() => handleValidateCoupon()}
                >
                  APLICAR
                </button>
              </div>
            </div>
            <Divider />

            <div className="confirm-payment-results">
              <div className="confirm-payment-subtotal">
                <p>Subtotal</p>
                <p>
                  {(
                    getProductPrice() + parseFloat(confirmPaymentProps.cuponData?.discount || 0)
                  ).toLocaleString("es-ES", { minimumFractionDigits: 2 })}
                  €
                </p>
              </div>
              <div className="confirm-payment-tasas">
                <p>Tasas</p>
                <p>0,00€</p>
              </div>
              {confirmPaymentProps.cuponData && confirmPaymentProps.cuponData.discount && (
                <div className="confirm-payment-tasas">
                  <p>Descuento promocional</p>
                  <p>- {confirmPaymentProps.cuponData.discount}€</p>
                </div>
              )}
            </div>

            <div className="short-divider">
              <Divider />
            </div>

            <div className="confirm-payment-final">
              <div className="confirm-payment-total">
                <p>Total</p>
                <div className="total-price">
                  {getProductPrice().toLocaleString("es-ES", { minimumFractionDigits: 2 })}€
                </div>
              </div>
              <div className="confirm-payment-button button-only-desktop">
                <button
                  disabled={
                    disableButtonOnConfirm ||
                    !confirmPaymentProps.formValid ||
                    (confirmPaymentProps.isEnPareja && !confirmPaymentProps.partnerFormValid)
                  }
                  onClick={handleClick}
                >
                  CONFIRMAR Y PAGAR
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ConfirmPayment
