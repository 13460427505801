import { useContext } from "react"
import { ProductsContext } from "../contexts/ProductsContext"

const useProducts = () => {
  const context = useContext(ProductsContext)
  if (!context) {
    throw new Error("You must to setup up ProductsProvider.")
  }
  return context
}

export default useProducts
