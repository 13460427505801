import "./Checkbox.css"

interface CheckboxProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

export default function PsychologySubscriptionCheckbox({
  label,
  checked,
  onChange
}: CheckboxProps) {
  return (
    <label
      htmlFor="psychology-subscription-checkbox-container"
      className="checkbox-container bf-checkbox"
    >
      <input
        type="checkbox"
        id="psychology-subscription-checkbox-container"
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        className="checkbox-input"
      />
      <span className="checkbox-custom"></span>
      <span className="checkbox-label">{label}</span>
    </label>
  )
}
