import parse from 'html-react-parser'
import './TestimonialCard.css'
import quatationUrl from '../../../../assets/img/quotation.svg'

interface TestimonialCardProps {
  details: string
  name: string
  plan: string
}

export default function TestimonialCard({ details, name, plan }: TestimonialCardProps) {
  return (
    <div className='bf-quote'>
      <blockquote className='bf-quote-blockquote'>
        <img src={quatationUrl} alt='quatation' className='bf-quotationIcon' />
        <div className='bf-quote-content'>
          <div className='bf-quote-details'>{parse(details)}</div>
          <div>
            <span className='bf-quote-name'>{name}</span>
            <br />
            <span className='bf-quote-plan'>{plan}</span>
          </div>
        </div>
      </blockquote>
    </div>
  )
}
