import "./Hero.css";
import Marquee from "./marquee/Marquee";
import VideoBg from "./video-background/VideoBg";
import Logo from "./logo/Logo";
import Countdown from "./countdown/Countdown";
import Button from "./button/Button";

export default function Hero({
  scrollToPromotions,
}: {
  scrollToPromotions: any;
}) {
  return (
    <div className="bf-hero-container">
      <VideoBg />
      <div className="bf-hero-marquee-wrapper">
        <Marquee />
      </div>

      <div className="bf-hero-logo-container">
        <Logo />
      </div>
      <div className="bf-hero-content">
        <Countdown />
        <div className="bf-hero-title-wrapper">
          <h1 className="bf-hero-title">
            Ofertas <br />
            Black Friday
          </h1>
          <h2 className="bf-hero-title-subtitle">VIKIKA TEAM</h2>
        </div>
        <Button onClick={scrollToPromotions} />
      </div>
    </div>
  );
}
