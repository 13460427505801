import "./RadioOption.css"

interface RadioOptionProps {
  id: string
  label: string
  name: string
  value: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function RadioOption({
  id,
  label,
  name,
  value,
  checked,
  onChange
}: RadioOptionProps) {
  return (
    <label className="radio-container bf-radio" htmlFor={id}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-input"
      />
      <span className="radio-custom"></span>
      <span className="radio-label">{label}</span>
    </label>
  )
}
