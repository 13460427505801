import { useState, useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Helmet } from "react-helmet-async"
import { trackView } from "../../../utils/FacebookPixelEvents"
import Cart from "./cart/Cart"
import FacturationForm from "./facturationForm/FacturationForm"
import CardForm from "./cardForm/CardForm"
import Questions from "../../shared/questions/Questions"
import Interesting from "../productDetails/interesting/Interesting"
import ConfirmPayment from "./confirmPayment/ConfirmPayment"
import { FacturationData } from "../../../models/FacturationData"
import { ProductType } from "../../../models/ProductType"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import "./Payment.css"
import "../productDetails/PlanDetails.css"
import PartnerForm from "./partnerForm/PartnerForm"
import { PartnerData } from "../../../models/PartnerData"
import useProducts from "../../../hooks/useProducts"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)

function Payment() {
  const navigate = useNavigate()
  const [facturationData, setFacturationData] = useState<FacturationData>({
    firstName: "",
    lastName: "",
    country: "",
    address_1: "",
    address_2: "",
    postalCode: "",
    city: "",
    province: "",
    phone: "",
    email: "",
    confirmEmail: "",
    state: "AV",
    password: "",
    confirmPassword: ""
  })

  const [PartnerFormData, setPartnerFormData] = useState<PartnerData>({
    partner_name: "",
    partner_last_name: "",
    partner_email: ""
  })

  const { products, loading: isLoadingProducts } = useProducts()

  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const [clientSecret, setClientSecret] = useState<string>("")
  const [payment_intent, setPaymentIntent] = useState<string>("")
  const [shouldFetchClientSecret, setShouldFetchClientSecret] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [partnerformValid, setPartnerFormValid] = useState(false)
  const [confirmPaymentAction, setConfirmPaymentAction] = useState(false)
  const [cuponData, setCuponData] = useState<any>(null)
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const onValidation = (isValid: boolean) => {
    setFormValid(isValid)
  }

  const onValidationPartnerForm = (isValid: boolean) => {
    setPartnerFormValid(isValid)
  }

  useEffect(() => {
    if (shouldFetchClientSecret) {
      const savedClientSecret = localStorage.getItem("client_secret")
      const savedPaymentIntent = localStorage.getItem("payment_intent")
      if (savedPaymentIntent && savedClientSecret) {
        setClientSecret(savedClientSecret)
        setPaymentIntent(savedPaymentIntent)
      }
    }
  }, [shouldFetchClientSecret])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    trackView()
    localStorage.removeItem("nextViewAfterLogin")
    const product = searchParams.get("product")
    if (product) {
      if (isBase64(product)) {
        const decodedProduct = atob(product)
        localStorage.setItem("savedSelectedProduct", decodedProduct)
      }
      searchParams.delete("product")
      setSearchParams(searchParams)
    }
    const savedSelectedProduct = localStorage.getItem("savedSelectedProduct")
    if (savedSelectedProduct) {
      const parsedSavedSelectedProduct = JSON.parse(savedSelectedProduct)
      setSelectedProduct(parsedSavedSelectedProduct)
    }
  }, [])

  useEffect(() => {
    const savedClientSecret = localStorage.getItem("client_secret")
    const savedPaymentIntent = localStorage.getItem("payment_intent")
    if (savedPaymentIntent && savedClientSecret) {
      setClientSecret(savedClientSecret)
      setPaymentIntent(savedPaymentIntent)
    }
  }, [])

  useEffect(() => {
    setClientSecret("")
  }, [])

  const handleProductClick = (product: ProductType) => {
    navigate(`/pago`)
  }

  if (!isLoadingProducts)
    return (
      <>
        <Helmet>
          <link rel="canonical" href={`${process.env.REACT_APP_FRONT_URL}` + "/pago"} />
        </Helmet>
        <main className="main-content payment_main_container">
          <div className="title-container">
            <h1>FINALIZAR COMPRA</h1>
          </div>
          <div className="cart-quantity">1 producto en el carrito</div>
          <div className="div-1">
            {selectedProduct && (
              <Cart
                selectedProduct={selectedProduct}
                cuponData={cuponData}
                setCuponData={setCuponData}
                productToShow={products.find(
                  product => product.category === selectedProduct.productCategory
                )}
              />
            )}
          </div>
          <div className="payment-container">
            <div className="payment-side1">
              <div className="div-2">
                {selectedProduct && selectedProduct.isEnPareja && (
                  <PartnerForm
                    setPartnerData={setPartnerFormData}
                    onValidation={onValidationPartnerForm}
                    facturationData={facturationData}
                  />
                )}
                <FacturationForm
                  setFacturationData={setFacturationData}
                  onValidation={onValidation}
                  partnerFormData={PartnerFormData}
                />
                {clientSecret === "" && (
                  <div className="confirm-payment-button button-only-mobile">
                    <button
                      disabled={
                        clientSecret !== "" ||
                        !formValid ||
                        (selectedProduct.isEnPareja && !partnerformValid)
                      }
                      onClick={() => setConfirmPaymentAction(true)}
                    >
                      CONFIRMAR Y PAGAR
                    </button>
                  </div>
                )}
              </div>

              <div className="facturation-container">
                {clientSecret !== "" && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CardForm
                      cuponData={cuponData}
                      setCuponData={setCuponData}
                      facturationData={facturationData}
                      partnerData={PartnerFormData}
                      payment_intent={payment_intent}
                      client_secret={clientSecret}
                      selectedProduct={selectedProduct}
                    />
                  </Elements>
                )}
              </div>
              <div className="others-products">
                <div className="payment-title-sesion">
                  <p>TAMBIÉN TE PUEDE INTERESAR </p>
                </div>
                <div className="product-cards-containers">
                  {selectedProduct &&
                    selectedProduct.productCategory &&
                    // eslint-disable-next-line array-callback-return
                    products.map(product => {
                      if (product.category !== selectedProduct.productCategory) {
                        //The map method should always return something.
                        return (
                          <Interesting
                            key={product.id}
                            {...product}
                            onClick={() => handleProductClick(product)}
                          />
                        )
                      }
                    })}
                </div>
              </div>
            </div>

            <div className="payment-side2">
              <ConfirmPayment
                cuponData={cuponData}
                setCuponData={setCuponData}
                selectedProduct={selectedProduct}
                facturationData={facturationData}
                partnerData={PartnerFormData}
                setShouldFetch={setShouldFetchClientSecret}
                formValid={formValid}
                partnerFormValid={partnerformValid}
                confirmPaymentAction={confirmPaymentAction}
                setConfirmPaymentAction={setConfirmPaymentAction}
                isEnPareja={selectedProduct.isEnPareja}
              />
            </div>
          </div>
        </main>
        <Questions />
      </>
    )
  return null
}

function isBase64(str: string) {
  try {
    return btoa(atob(str)) === str
  } catch (e) {
    return false
  }
}

export default Payment
