import './Questions.css'
import Facebook from '../../../assets/img/facebook1.svg'
import Instagram from '../../../assets/img/instagram1.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import React from 'react'

interface QuestionProps {
  showAlgunaDuda?: boolean
  applyMarginTop?: boolean
}

const Questions: React.FC<QuestionProps> = ({ showAlgunaDuda = true }) => {
  const navigate = useNavigate()
  const handleSendMessageClick = () => {
    navigate('/contacto')
  }
  const currentLocation = useLocation()

  // Verificar si la ruta actual incluye "/private"
  const isPrivateRoute = currentLocation.pathname.includes('/private')

  return (
    <>
      {!isPrivateRoute ? (
        <div className='questions-container'>
          {showAlgunaDuda && (
            <div className='side-questions-a'>
              <div className='questions-text-container'>
                <h2>¿ALGUNA DUDA?</h2>
              </div>
              <div>
                <button className='button-message' onClick={handleSendMessageClick} style={{ cursor: 'pointer' }}>
                  {' '}
                  <p>MÁNDANOS UN MENSAJE</p>{' '}
                </button>
              </div>
            </div>
          )}
          <div className='side-questions-b'>
            <p className='spacer-social'>ÚNETE A NUESTRA COMUNIDAD</p>
            <div className='social-row'>
              <Link to='https://www.facebook.com/vikikateam' target='_blank'>
                <img src={Facebook} alt='logo' className='facebook1' />
              </Link>
              <Link to='https://www.instagram.com/vikikateam/' target='_blank'>
                <img src={Instagram} alt='logo' className='instagram1' />
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className='questions-container questions-container-private'>
          <div className='side-questions-b'>
            <p className='spacer-social'>ÚNETE A NUESTRA COMUNIDAD</p>
            <div className='social-row'>
              <Link to='https://www.facebook.com/vikikateam' target='_blank'>
                <img src={Facebook} alt='logo' className='facebook1' />
              </Link>
              <Link to='https://www.instagram.com/vikikateam/' target='_blank'>
                <img src={Instagram} alt='logo' className='instagram1' />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Questions
