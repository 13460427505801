import { useState } from "react"
import "./Toggle.css"

interface ToggleProps {
  onToggle?: (selectedOption: "INDIVIDUAL" | "EN_PAREJA") => void
}

export default function TogglePsychology({ onToggle }: ToggleProps) {
  const [isCouple, setIsCouple] = useState(false)

  const handleToggle = () => {
    const newValue = !isCouple
    setIsCouple(newValue)

    const selectedOption = newValue ? "EN_PAREJA" : "INDIVIDUAL"

    if (onToggle) {
      onToggle(selectedOption)
    }
  }

  return (
    <div className="bf-promotions-toggle-wrapper psychology">
      <input
        type="checkbox"
        id="toggle-psychology-checkbox"
        checked={isCouple}
        onChange={handleToggle}
        className="bf-checkbox"
      />
      <label
        htmlFor="toggle-psychology-checkbox"
        className={`toggle-label psychology ${isCouple ? "active" : ""}`}
      >
        <span className={`bf-individual psychology ${isCouple ? "" : "active"}`}>Individual</span>
        <span className={`bf-couple psychology ${isCouple ? "active" : ""}`}>En pareja</span>
      </label>
    </div>
  )
}
