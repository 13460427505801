import React, { createContext, useState, useEffect } from "react"
import { ProductType } from "../models/ProductType"
import { productsService } from "../services/productsService"

type ProductsContextType = {
  products: ProductType[]
  loading: boolean
  error: string | null
}

const ProductsContext = createContext<ProductsContextType | undefined>(undefined)

export const ProductsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<ProductType[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true)
        const products = await productsService()
        setProducts(products)
      } catch (err) {
        setError("Error fetching products.")
      } finally {
        setLoading(false)
      }
    }

    fetchProducts()
  }, [])

  return (
    <ProductsContext.Provider value={{ products, loading, error }}>
      {children}
    </ProductsContext.Provider>
  )
}

export { ProductsContext }
export default ProductsProvider
