import "./Info.css"

export default function Info() {
  return (
    <section className="bf-info-wrapper">
      <h2 className="bf-info-bold-text">¡Último día de ofertas con Cyber Monday!</h2>
      <p className="bf-info-text">
        Aprovecha el último día de ofertas en vikika team con descuentos en todos nuestros
        productos.
      </p>
    </section>
  )
}
