import Hero from "./hero/Hero"
import Info from "./info/Info"
import Promotions from "./promotions/Promotions"
import Team from "./team/Team"
import Testimonial from "./testimonial/Testimonial"
import Faq from "./faq/Faq"
import { useEffect, useState } from "react"
import { productDetailService } from "../../../services/productDetailService"
import { ProductDetailData } from "../../../models/ProductDetailData"

const BlackFriday = () => {
  const [dietProducts, setDietProducts] = useState<ProductDetailData[]>([])

  const [psychologyProducts, setPsychologyProducts] = useState<ProductDetailData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const dietProducts = await productDetailService("diet")
      const psychologyProducts = await productDetailService("psychology")
      setDietProducts(dietProducts)
      setPsychologyProducts(psychologyProducts)
    }
    fetchData()
  }, [])

  const scrollToPromotions = () => {
    const promotionsElement = document.getElementById("promotions")
    if (promotionsElement) {
      promotionsElement.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div className="bf-landing">
      <Hero scrollToPromotions={scrollToPromotions} />
      <Info />
      <div id="promotions">
        <Promotions dietProducts={dietProducts} psychologyProducts={psychologyProducts} />
      </div>
      <Team />
      <Faq />
      <Testimonial />
    </div>
  )
}

export default BlackFriday
