import "./VideoBg.css"
import videoPortraitUrl from "../../../../../assets/blackfriday/vikika-team-portrait.mp4"
import videoLandscapeUrl from "../../../../../assets/blackfriday/vikika-team-landscape.mp4"

const VideoBg = () => {
  return (
    <>
      <video className="bf-video" autoPlay loop muted playsInline>
        {/* Video para dispositivos móviles */}
        <source src={videoPortraitUrl} type="video/mp4" media="(max-width: 600px)" />
        {/* Video para dispositivos de escritorio */}
        <source src={videoLandscapeUrl} type="video/mp4" media="(min-width: 601px)" />
      </video>
    </>
  )
}

export default VideoBg
