import './Testimonial.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import TestimonialCard from './TestimonialCard'
import Slider from 'react-slick'
import { useEffect, useState } from 'react'
import { TestimonialType } from '../../../../models/TestimonialType'
import { testimonialsService } from '../../../../services/testimonialsService'
import { CATEGORY_DIETA, TESTIMONIALS_SLIDER_URL } from '../../../../utils/constants'
import { useNavigate } from 'react-router-dom'

export default function Testimonial() {
  const navigate = useNavigate()
  const [testimonials, setTestimonials] = useState<TestimonialType[]>([])

  useEffect(() => {
    async function fetchTestimonials() {
      const testimonials = await testimonialsService(TESTIMONIALS_SLIDER_URL)
      setTestimonials(testimonials)
    }
    fetchTestimonials()
  }, [])

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1500,
    centerMode: false,
    cssEase: 'ease-in-out',
    dots: true,
    infinite: true,
  }

  const moreTestimonial = () => {
    navigate(`/testimonios`)
  }

  return (
    <section className='bf-testimonial-content'>
      <div className='bf-testimonial-title-wrapper'>
        <h2 className='bf-testimonial-title'>Lo que opinan de nosotros</h2>
      </div>
      <div className='bf-testimonial-slider'>
        <Slider {...settings}>
          {testimonials.map((quote, index) => (
            <TestimonialCard
              key={index}
              details={quote.description}
              name={quote.title}
              plan={quote.category === CATEGORY_DIETA ? 'Plan de Dieta' : 'Sesiones de Psicología'}
            />
          ))}
        </Slider>
      </div>
      <div className='bf-testimonial-button-wrapper'>
        <button onClick={() => moreTestimonial()} className='bf-testimonial-button'>
          ¿QUIERES SABER MÁS?
        </button>
      </div>
    </section>
  )
}
