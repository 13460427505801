import { useState } from 'react'
import './Psychology.css'
import Title from '../components/title/Title'
import TogglePsychology from '../components/toggle/TogglePsychology'
import PsychologyPromo from '../components/promo/PsychologyPromo'
import { ProductDetailData } from '../../../../../models/ProductDetailData'
import PrivateLoading from '../../../../shared/loading/PrivateLoading'

type PsychologyProps = {
  products: ProductDetailData[]
}

export default function Psychology({ products }: PsychologyProps) {
  const [inCouple, setInCouple] = useState<boolean>(false)

  const handleToggleChange = (option: 'INDIVIDUAL' | 'EN_PAREJA') => {
    if (option === 'INDIVIDUAL') setInCouple(false)
    else setInCouple(true)
  }

  console.log(products)

  return (
    <div className='bf-promotions-container bf-psychology-container'>
      <div className='bf-promotions-content'>
        <div className='bf-promotions-title-subcontent'>
          <Title
            title={'Plan Psicología'}
            subtitle='Salud física y mental van de la mano con  nuestras sesiones de psicología online.'
          />
        </div>
        <div className='bf-promotions-subcontent'>
          <TogglePsychology onToggle={handleToggleChange} />
          {products.length > 0 ? (
            <PsychologyPromo products={products} inCouple={inCouple} />
          ) : (
            <div style={{ height: '680px', width: '100%', backgroundColor: '#f3f3f3', position: 'relative' }}>
              <PrivateLoading />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
