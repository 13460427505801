import React from 'react';
import './MaintenancePage.css';

const MaintenancePage = () => {
    return (
        <div className="maintenance-container">
            <div className="maintenance-content">
                <h1>¡Volvemos pronto!</h1>
                <p>Lo sentimos, estamos realizando algunas mejoras en la aplicación.</p>
                <p>Gracias por tu paciencia. Estaremos de vuelta antes de lo que imaginas.</p>
                <p className="footer-text">Vuelve a visitarnos más tarde.</p>
            </div>
        </div>
    );
};

export default MaintenancePage;
