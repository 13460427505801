import "./Button.css";

export interface ButtonProps {
  onClick?: () => void;
}

const Button = ({ onClick }: ButtonProps) => {
  return (
    <button onClick={onClick} className="bf-hero-cta">
      ¡DESCUBRE LAS OFERTAS!
    </button>
  );
};

export default Button;
