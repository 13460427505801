import ProductCard from "./productCard/ProductCard"
import useProducts from "../../../../hooks/useProducts"
import "./Products.css"

const Products = () => {
  const { products } = useProducts()

  return (
    <>
      <div className="title-home" id="product_cards">
        <p>NUESTROS PRODUCTOS</p>
      </div>
      <div className="cards-containers">
        <div className="product-container">
          {products.map(product => (
            <ProductCard key={product.id} {...product} />
          ))}
        </div>
      </div>
    </>
  )
}

export default Products
