import { useState, useEffect } from "react"
import isBlackFriday from "../../../utils/isBlackFriday"

import "./VitalPromo.css"
import logoVWUrl from "../../../../../../assets/img/logo-vital-wellness.svg"
import infoIconUrl from "../../../../../../assets/img/info-circle.png"

export default function Promo() {
  const [currentPrice, setCurrentPrice] = useState<string>("381,65")
  const [prevPrice, setPrevPrice] = useState<string>("449")
  const [showPromoButton, setShowPromoButton] = useState<boolean>(false)

  useEffect(() => {
    const today = new Date()
    const changeDate = new Date("2024-12-02")
    if (today >= changeDate) {
      setCurrentPrice("404,10")
      setPrevPrice("449")
    }
    setShowPromoButton(isBlackFriday(today))
  }, [])

  return (
    <div className="bf-vital-promo-wrapper">
      {/* Price section */}
      <div className="bf-vital-promo-price">
        <div className="bf-vital-promo-price-container">
          <div className="bf-vital-promo-price-wrapper">
            <div className="bf-vital-promo-price">
              <h2 className="bf-vital-promo-price-number">{currentPrice}</h2>
              <div>
                <h3 className="bf-vital-promo-price-promo">PROMO</h3>
                <p className="bf-vital-promo-price-simbol">€/mes</p>
              </div>
            </div>
            <p className="bf-vital-promo-prev-price">{prevPrice}€</p>
          </div>
        </div>
        <img src={logoVWUrl} alt="logo vital wellness" className="bf-vital-promo-logo" />
      </div>

      {/* Info section */}
      <div className="bf-vital-promo-info-wrapper">
        <p className="bf-vital-promo-info-text">
          Este plan está creado para aquellos que quieran lograr un cambio de hábitos y estilo de
          vida de <strong>forma integral</strong> a través de la nutrición, el entrenamiento y la
          psicología.
        </p>

        <div className="bf-vital-promo-info-extra">
          <img src={infoIconUrl} alt="info" className="bf-vital-promo-info-icon" />
          <p className="bf-vital-promo-info-anchor">
            Si tienes dudas de cómo funciona te lo explicamos{" "}
            <a href="https://vitalwellnessbyvikikos.com/" target="_blank" rel="noreferrer">
              aquí
            </a>
          </p>
        </div>
      </div>

      {/* Button section */}
      {showPromoButton && (
        <div className="bf-vital-promo-button-wrapper">
          <a className="bf-promo-cta" href="https://vitalwellnessbyvikikos.com/">
            COMPRAR
          </a>
          <p className="bf-vital-promo-info-anchor">
            Te remitimos a la web de{" "}
            <a href="https://vitalwellnessbyvikikos.com/">Vital Wellness</a> para terminar tu
            compra.
          </p>
        </div>
      )}
    </div>
  )
}
