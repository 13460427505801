import "./Logo.css"

const Logo = () => {
  return (
    <div className="bf-logo-img">
      <svg viewBox="0 0 182 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M109.104 48.9976C108.686 48.9976 108.399 48.7968 108.243 48.395L106.09 42.7303H94.2691L92.1163 48.395C91.9597 48.7968 91.6727 48.9976 91.2551 48.9976H90.1592L100.727 22H101.432L112 48.9976H109.104ZM94.817 41.3644H105.542L100.179 27.3835L94.817 41.3644Z"
          fill="white"
        />
        <path
          d="M74.6877 37.4272C74.4007 37.1058 74.218 36.7844 74.1397 36.463H74.0614V48.9976H70.7344V22.0803H74.0614V34.7355H74.1397C74.2441 34.3873 74.4528 34.0525 74.766 33.7311L85.3734 22.5624C85.6604 22.241 86.0257 22.0803 86.4694 22.0803H88.035L77.3493 33.2088C77.0623 33.4766 76.8666 33.7043 76.7622 33.8918C76.6839 34.0525 76.6448 34.2132 76.6448 34.3739C76.6448 34.5346 76.6839 34.6953 76.7622 34.856C76.8666 35.0167 77.0492 35.2443 77.3102 35.539L89.5615 48.9976H85.8431C85.4256 48.9976 85.0472 48.8235 84.708 48.4753L74.6877 37.4272Z"
          fill="white"
        />
        <path d="M64.2768 48.9976H60.9497V22.0803H64.2768V48.9976Z" fill="white" />
        <path
          d="M42.656 37.4272C42.3689 37.1058 42.1863 36.7844 42.108 36.463H42.0297V48.9976H38.7026V22.0803H42.0297V34.7355H42.108C42.2123 34.3873 42.4211 34.0525 42.7342 33.7311L53.3416 22.5624C53.6287 22.241 53.994 22.0803 54.4376 22.0803H56.0033L45.3176 33.2088C45.0305 33.4766 44.8348 33.7043 44.7305 33.8918C44.6522 34.0525 44.613 34.2132 44.613 34.3739C44.613 34.5346 44.6522 34.6953 44.7305 34.856C44.8348 35.0167 45.0175 35.2443 45.2784 35.539L57.5298 48.9976H53.8113C53.3938 48.9976 53.0155 48.8235 52.6762 48.4753L42.656 37.4272Z"
          fill="white"
        />
        <path d="M32.245 48.9976H28.918V22.0803H32.245V48.9976Z" fill="white" />
        <path
          d="M6.89649 22.0803C7.34009 22.0803 7.62713 22.2812 7.75761 22.6829L15.6642 43.8552L23.5709 22.6829C23.7013 22.2812 23.9884 22.0803 24.432 22.0803H25.4888L15.1945 49.0779H14.2943L4 22.0803H6.89649Z"
          fill="white"
        />
        <path
          d="M167.918 42.9583L175.558 30.5361H176V47.9999H173.791V35.1879L167.424 45.4791H167.086L160.667 35.3699V47.9999H159.628V30.5361H160.07L167.918 42.9583Z"
          fill="white"
        />
        <path
          d="M155.353 47.9999C155.076 47.9999 154.886 47.87 154.782 47.6101L153.352 43.9458H145.504L144.075 47.6101C143.971 47.87 143.78 47.9999 143.503 47.9999H142.775L149.792 30.5361H150.26L157.277 47.9999H155.353ZM145.868 43.0622H152.989L149.428 34.0185L145.868 43.0622Z"
          fill="white"
        />
        <path
          d="M141.192 47.9999H134.254C133.11 47.9999 132.218 47.6794 131.577 47.0384C130.936 46.38 130.615 45.4705 130.615 44.3097V30.5881H140.932V31.9915C140.343 31.8875 139.772 31.8096 139.217 31.7576C138.663 31.6883 138.074 31.6363 137.45 31.6017C136.826 31.5497 136.142 31.515 135.397 31.4977C134.652 31.4804 133.794 31.4717 132.824 31.4717V38.3065H139.451V39.1901H132.824V44.5695C132.824 46.2674 133.63 47.1163 135.241 47.1163H141.192V47.9999Z"
          fill="white"
        />
        <path
          d="M120.17 31.4717C119.459 31.4717 118.836 31.4804 118.298 31.4977C117.761 31.515 117.268 31.5497 116.817 31.6017C116.384 31.6363 115.977 31.6883 115.596 31.7576C115.215 31.8096 114.825 31.8875 114.426 31.9915V30.5881H128.122V31.9915C127.741 31.8875 127.36 31.8096 126.978 31.7576C126.597 31.6883 126.181 31.6363 125.731 31.6017C125.298 31.5497 124.804 31.515 124.25 31.4977C123.713 31.4804 123.089 31.4717 122.379 31.4717V47.9999H120.17V31.4717Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

export default Logo
