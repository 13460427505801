import { ProductDetailData } from '../../../../models/ProductDetailData'
import Nutrition from './nutrition/Nutrition'
import Psychology from './psychology/Psychology'
import VitalWelness from './vital-wellness/VitalWellness'

import './Promotions.css'

type PromotionProps = {
  dietProducts: ProductDetailData[]
  psychologyProducts: ProductDetailData[]
}

export default function Promotions({ dietProducts, psychologyProducts }: PromotionProps) {
  return (
    <div>
      <Nutrition products={dietProducts} />
      <Psychology products={psychologyProducts} />
      <VitalWelness />
    </div>
  )
}
