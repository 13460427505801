import { useEffect, useRef, useState } from 'react'
import './Marquee.css'

const Marquee = () => {
  const marqueeRef = useRef<HTMLDivElement | null>(null)
  const [isAnimated, setIsAnimated] = useState(false)
  const [animationDuration, setAnimationDuration] = useState<number | null>(null)
  const [elementWidth, setElementWidth] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    const handleElementResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        setElementWidth(entry.contentRect.width)
      }
    }

    const resizeObserver = new ResizeObserver(handleElementResize)

    const element = marqueeRef.current?.querySelector('.bf-marquee-text') as HTMLElement | null
    if (element) {
      const elementSize = element.offsetWidth
      const calculatedDuration = elementSize / 100
      setAnimationDuration(calculatedDuration)
    }

    if (element) {
      resizeObserver.observe(element)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [])

  useEffect(() => {
    setIsAnimated(elementWidth > windowWidth)
  }, [elementWidth, windowWidth])

  const marqueeText = '¡OFERTAS BLACK FRIDAY EN TODOS NUESTROS PLANES!'

  return (
    <div className='bf-marquee-container' ref={marqueeRef}>
      <div
        className={isAnimated ? 'bf-marquee-text-scrolling' : 'bf-marquee-text'}
        style={isAnimated ? { animationDuration: `${animationDuration}s` } : {}}
      >
        {marqueeText}
      </div>

      {isAnimated && (
        <>
          <div className=' bf-marquee-text-scrolling' style={{ animationDuration: `${animationDuration}s` }}>
            {marqueeText}
          </div>
        </>
      )}
    </div>
  )
}

export default Marquee
