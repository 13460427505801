import { useState } from "react"
import "./Nutrition.css"
import Title from "../components/title/Title"
import ToggleNutrition from "../components/toggle/ToggleNutrition"
import NutritionPromo from "../components/promo/NutritionPromo"
import { ProductDetailData } from "../../../../../models/ProductDetailData"
import PrivateLoading from "../../../../shared/loading/PrivateLoading"

type NutritionProps = {
  products: ProductDetailData[]
}

export default function Nutrition({ products }: NutritionProps) {
  const [inCouple, setInCouple] = useState<boolean>(false)

  const handleToggleChange = (option: "INDIVIDUAL" | "EN_PAREJA") => {
    if (option === "INDIVIDUAL") setInCouple(false)
    else setInCouple(true)
  }

  return (
    <div className="bf-promotions-container bf-nutrition-container">
      <div className="bf-promotions-content">
        <div className="bf-promotions-title-subcontent">
          <Title
            title={
              <>
                Plan Dieta +<br />
                Plan Entrenamiento
              </>
            }
            subtitle="¡Elige el plan a tu medida! Puedes escoger entre plan dieta con o sin entrenamiento, individual o en pareja."
          />
        </div>
        <div className="bf-promotions-subcontent">
          <ToggleNutrition onToggle={handleToggleChange} />
          {products.length > 0 ? (
            <NutritionPromo product={products[0]} inCouple={inCouple} />
          ) : (
            <div
              style={{
                height: "430px",
                width: "100%",
                backgroundColor: "#f3f3f3",
                position: "relative"
              }}
            >
              <PrivateLoading />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
