import "./VitalWellness.css"
import Title from "../components/title/Title"
import VitalPromo from "./vital-promo/VitalPromo"

export default function VitalWelness() {
  return (
    <div className="bf-vital-wellness-container">
      <div className="bf-promotions-content">
        <div className="bf-promotions-title-subcontent">
          <Title
            title={"Plan Vital Wellness"}
            subtitle="Si buscas una transformación integral en tu alimentación, entrenamiento y tu mente este es tu plan."
          />
        </div>
        <div className="bf-promotions-subcontent">
          <VitalPromo />
        </div>
      </div>
    </div>
  )
}
