import { useEffect, useState } from "react"
import "./Countdown.css"
import beforeIcon from "../../../../../assets/img/solo-queda-yellow.svg"
import afterIcon from "../../../../../assets/img/solo-queda-red.svg"
import { BLACKFRIDAY_DATES } from "../../utils/isBlackFriday"

export default function Countdown() {
  const [showCountdown, setShowCountdown] = useState(false)
  const [icon, setIcon] = useState(beforeIcon)
  const [timeUntilEvent, setTimeUntilEvent] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const finishCountdown = new Date(BLACKFRIDAY_DATES.end)
    finishCountdown.setHours(finishCountdown.getHours() - 48)

    const updateCountdown = () => {
      const today = new Date()

      if (today < BLACKFRIDAY_DATES.start) {
        // Antes de Black Friday
        setShowCountdown(true)
        setIcon(beforeIcon)
        setTimeUntilEvent(getTimeDifference(today, BLACKFRIDAY_DATES.start))
      } else if (today >= BLACKFRIDAY_DATES.start && today < finishCountdown) {
        // Durante Black Friday
        setShowCountdown(false)
      } else if (today >= finishCountdown && today < BLACKFRIDAY_DATES.end) {
        // Dentro de las 48 horas antes del endDate
        setShowCountdown(true)
        setIcon(afterIcon)
        setTimeUntilEvent(getTimeDifference(today, BLACKFRIDAY_DATES.end))
      } else {
        // Fuera de los rangos
        setShowCountdown(false)
      }
    }

    updateCountdown()
    const interval = setInterval(updateCountdown, 1000)

    return () => clearInterval(interval)
  }, [])

  const getTimeDifference = (from: Date, to: Date) => {
    const diffTime = to.getTime() - from.getTime()
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60))
    const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000)
    return { hours: diffHours, minutes: diffMinutes, seconds: diffSeconds }
  }

  const formatTime = (time: number | null) => {
    return time !== null ? String(time).padStart(2, "0") : "00"
  }

  return showCountdown ? (
    <div className="bf-countdown-container">
      <img src={icon} alt="solo-quedan" className="bf-countdown-icon" />
      <div className="bf-countdown-wrapper">
        <div className="bf-countdown-time-box">
          <span className="bf-countdown-time-label">horas</span>
          <span className="bf-countdown-time-number">{formatTime(timeUntilEvent.hours)}</span>
        </div>
        <div className="bf-countdown-time-box">
          <span className="bf-countdown-time-label">min.</span>
          <span className="bf-countdown-time-number">{formatTime(timeUntilEvent.minutes)}</span>
        </div>
        <div className="bf-countdown-time-box">
          <span className="bf-countdown-time-label">seg.</span>
          <span className="bf-countdown-time-number">{formatTime(timeUntilEvent.seconds)}</span>
        </div>
      </div>
    </div>
  ) : null
}
