import React, { useEffect, useState, useCallback } from "react"
import { Helmet } from "react-helmet-async"
import DenyIcon from "../../../assets/img/deny.png"
import "./CookieBanner.css"
import Modal from "react-modal"
import { useCookies } from "react-cookie"
import { useLocation } from "react-router-dom"

declare const window: Window &
  typeof globalThis & {
    dataLayer: any
  }
const CookieBanner = () => {
  const location = useLocation()
  const [cookies, setCookie] = useCookies(["allowcookiesGTM"])
  const [cookieGTM, setCookieGTM] = useCookies(["allowcookies"])
  const [decisionMade, setDecisionMade] = useState(false)

  function handleCookie(consentBoolean: boolean) {
    console.log("Handling cookie...")
    setCookieGTM("allowcookies", consentBoolean, {
      path: "/",
      expires: new Date(Date.now() + 100 * 24 * 60 * 60 * 1000)
    })
    setCookie("allowcookiesGTM", true, {
      path: "/",
      expires: new Date(Date.now() + 100 * 24 * 60 * 60 * 1000)
    })
  }

  function closeModal(consentBoolean: boolean) {
    console.log("Closing modal...")
    handleCookie(consentBoolean)
  }

  function gtag(...args: any[]) {
    console.log("Calling gtag...")
    ;(window as { [key: string]: any }).dataLayer =
      (window as { [key: string]: any }).dataLayer || []
    ;(window as { [key: string]: any }).dataLayer.push(...args)
  }

  const sendConsent = useCallback((consent: any) => {
    console.log("Sending consent...")
    gtag("consent", "default", consent)
  }, [])

  const handleDecision = (outcome: string) => {
    const consent = {
      ad_storage: outcome,
      analytics_storage: outcome,
      ad_user_data: outcome,
      ad_personalization: outcome
    }
    sendConsent(consent)
    setDecisionMade(true)
    closeModal(outcome === "granted" ? true : false)
  }
  const modalStyles = {
    content: {
      zIndex: 1000
    },
    overlay: {
      zIndex: 999
    }
  }
  return location.pathname !== "/cookies" ? (
    <Modal
      isOpen={cookies.allowcookiesGTM ? false : true}
      className="Modal"
      overlayClassName="Overlay"
      style={modalStyles}
    >
      <div className="main-cookie-banner">
        <div className="cookie-banner">
          <p className="cookie-banner-content">
            Usamos cookies propias y de terceros para ofrecerte una mejor experiencia.
            <br></br>Puedes revisar toda la información sobre nuestra{" "}
            <a href="/cookies">política de cookies</a>
          </p>
          <div className="cookie-buttons">
            <button className="accept-cookies" onClick={() => handleDecision("granted")}>
              ACEPTAR TODAS
            </button>
            <button onClick={() => handleDecision("denied")}>
              <img src={DenyIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  )
}

export default CookieBanner
