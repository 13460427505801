import { ReactNode } from "react";
import "./Title.css";

interface TitleProps {
  title: ReactNode;
  subtitle: string;
}

export default function Title({ title, subtitle }: TitleProps) {
  return (
    <div className="bf-promotions-title-wrapper">
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
}
