import React, { useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'

import './App.css'
import Header from './components/shared/header/Header'
import Footer from './components/shared/footer/Footer'
import ScrollToTopOnNavigate from './components/shared/scrollToTop/ScrollToTopOnNavigate'
import CookieBanner from './components/views/cookies/CookieBanner'

// Components
import Home from './components/views/home/Home'
import Login from './components/views/login/Login'
import ProductDetails from './components/views/productDetails/PlanDetails'
import Payment from './components/views/payment/Payment'
import Testimonials from './components/views/testimonials/Testimonials'
import Faq from './components/views/faqs/Faq'
import Team from './components/views/team/Team'
import Reset from './components/views/reset/Reset'
import Private from './components/views/private/Private'
import Contact from './components/views/contact/Contact'
import Profile from './components/views/profile/Profile'
import Warning from './components/views/warning/Warning'
import Privacy from './components/views/privacy/Privacy'
import Conditions from './components/views/conditions/Conditions'
import MyData from './components/views/private/myData/MyData'
import Work from './components/views/work/Work'
import PlansSubscriptions from './components/views/plans-subscriptions/plans-subscriptions'
import BuySubscriptions from './components/views/private/buySubscriptions/BuySubscriptions'
import NewClientBuySubscriptions from './components/views/private/buySubscriptions/noClientBuySubscriptions/BuySubscriptions'
import NewClientBuySubscriptionsVitalWellness from './components/views/private/buySubscriptions/noClientBuySubscriptions/BuySubscriptionsVitalWellness'
import RenewSubscriptions from './components/views/private/buySubscriptions/renewSubscriptions/RenewSubscriptions'
import ReviewSubscriptions from './components/views/private/buySubscriptions/reviewSubscriptions/ReviewSubscriptions'
import MaintenancePage from './components/views/maintenance/MaintenancePage' // Import MaintenancePage
import BlackFriday from './components/views/blackfriday/BlackFriday'
import MenuContext from './providers/MenuProvider';

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false) // Flag for maintenance mode
  const [showMenuPrincipal, setShowMenuPrincipal] = useState<boolean>(false);

  return (
    <HelmetProvider>
      <QueryClientProvider client={new QueryClient()}>
        <CookiesProvider>
          <main className='App notranslate'>
            <Router>
              {isMaintenanceMode ? (
                // Only render MaintenancePage without Header, Footer, or other layout components
                <Routes>
                  <Route path='*' element={<MaintenancePage />} />
                </Routes>
              ) : (
                <MenuContext.Provider value={{showMenuPrincipal, setShowMenuPrincipal}}>
                <AppLayout />
                </MenuContext.Provider>
              )}
            </Router>
          </main>
        </CookiesProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

// Separa el layout principal
function AppLayout() {
  const location = useLocation()

  // Lista de rutas que excluyen el Header
  const excludedRoutes = ['/blackfriday']

  // Verificar si la ruta actual está en la lista de exclusión
  const shouldExcludeHeader = excludedRoutes.includes(location.pathname)

  return (
    <>
      {/* Renderizar Header solo si no está en las rutas excluidas */}
      {!shouldExcludeHeader && <Header />}
      <ScrollToTopOnNavigate />
      <CookieBanner />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/iniciar-sesion' element={<Login />} />
        <Route path='/login' element={<Navigate to="/iniciar-sesion" replace />} />
        <Route path='/productos/:id' element={<ProductDetails />} />
        <Route path='/pago' element={<Payment />} />
        <Route path='/testimonios' element={<Testimonials />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/equipo' element={<Team />} />
        <Route path='/contacto' element={<Contact />} />
        <Route path='/profile/:id' element={<MyData />} />
        <Route path='/recover-password' element={<Reset />} />
        <Route path='/private' element={<Private />} />
        <Route path='/privacidad' element={<Privacy />} />
        <Route path='/aviso-legal' element={<Warning />} />
        <Route path='/condiciones-generales' element={<Conditions />} />
        <Route path='/trabaja-con-nosotros' element={<Work />} />
        <Route path='/plans_subscriptions' element={<PlansSubscriptions />} />
        <Route path='/buy_subscriptions' element={<BuySubscriptions />} />
        <Route path='/renew_subscriptions' element={<RenewSubscriptions />} />
        <Route path='/review_subscriptions' element={<ReviewSubscriptions />} />
        <Route path='/cuestionario_inicial' element={<NewClientBuySubscriptions />} />
        <Route path='/cuestionario_inicial_vital_wellness' element={<NewClientBuySubscriptionsVitalWellness />} />
        <Route path='/blackfriday' element={<BlackFriday />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
